import { redirect } from './url'

async function httpFetch (url, params = {}) {
  if (params.body && typeof params.body === 'object') {
    params.body = JSON.stringify(params.body)
  }

  let data
  const response = await fetch(url, params)
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    data = await response.json()
  } else {
    data = await response.text()
  }

  if (response.status === 201) {
    redirect(response.headers.get('Location'))
  }

  if (response.ok) {
    return data
  } else {
    throw new Error(data)
  }
}

export async function jsonFetch (url, params = {}) {
  return httpFetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    ...params
  })
}

export async function jsonFetchForm (url, params = {}) {
  if (Object.prototype.hasOwnProperty.call(params, 'body')) {
    if (params.body.has('_method')) {
      params.body.delete('_method')
    }
    params.body = new URLSearchParams(params.body).toString()
  }

  return httpFetch(url, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      charset: 'utf-8'
    },
    ...params
  })
}
